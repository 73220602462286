import css from './styles.scss'
import ParentReview, { TypeParentReviewFields } from './ParentReview'
import { Carousel, CarouselItem, AnchoredH } from '../Utilities'

interface ReviewEntryReference {
  fields: TypeParentReviewFields
  sys: Record<string, unknown>
}

interface TypeRealParentReviewsFields {
  reviews: ReviewEntryReference[]
  heading: string
}

interface TypeRealParentReviewsProps {
  fields: TypeRealParentReviewsFields
}

const RealParentReviews: React.FC<TypeRealParentReviewsProps> = ({
  fields,
}: TypeRealParentReviewsProps) => (
  <aside>
    <AnchoredH headingType="h2" textForAnchor={fields.heading}>
      {fields.heading}
    </AnchoredH>

    {fields.reviews.length > 2 ? ( // Only use carousel if > 2 reviews
      <Carousel>
        {fields.reviews.map((review) => (
          <CarouselItem
            carouselWidth={324}
            className={css.ColoredCard}
            key={`parentReview-${review.sys.id}`}
          >
            <ParentReview {...review.fields} />
          </CarouselItem>
        ))}
      </Carousel>
    ) : (
      <div className={css.RealParentReviews__flex}>
        {fields.reviews.map((review) => (
          <div
            className={css.ColoredCard}
            key={`parentReview-${review.sys.id}`}
          >
            <ParentReview {...review.fields} />
          </div>
        ))}
      </div>
    )}
  </aside>
)

export default RealParentReviews
