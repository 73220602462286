import { Block, Text } from '@contentful/rich-text-types'
import { ReactNode } from 'react'
import { AnchoredH } from '../Embeds/Utilities'

export interface HeadingProps {
  block: Block
  children: ReactNode
  headingType: 'h2' | 'h3'
}

const Heading: React.FC<HeadingProps> = ({
  block,
  children,
  headingType,
}: HeadingProps) => {
  const { content } = block

  return (
    <AnchoredH
      headingType={headingType}
      textForAnchor={(content[0] as Text)?.value}
    >
      {children}
    </AnchoredH>
  )
}

export default Heading
