import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import {
  Document,
  BLOCKS,
  Node,
  Block,
  INLINES,
} from '@contentful/rich-text-types'
import Image from 'components/image'

import { ReactNode } from 'react'

import EmbeddedEntry, { AllowedEmbeds } from '../Embeds/EmbeddedEntry'

import Heading from './Heading'
import Hyperlink from './Hyperlink'

import css from './RichTextBody.styles.scss'

export interface RichTextBodyProps {
  richTextBody: Document
  serverData?: ServerData
  allowedEmbeds?: AllowedEmbeds[] | null
  hyperlinkClassName?: string
}

const RichTextBody: React.FC<RichTextBodyProps> = ({
  richTextBody,
  serverData,
  allowedEmbeds = null,
  hyperlinkClassName,
}: RichTextBodyProps) => {
  const richTextOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node: Node, children: ReactNode) => (
        <Heading block={node as Block} headingType="h2">
          {children}
        </Heading>
      ),

      [BLOCKS.HEADING_3]: (node: Node, children: ReactNode) => (
        <Heading block={node as Block} headingType="h3">
          {children}
        </Heading>
      ),

      [BLOCKS.EMBEDDED_ENTRY]: (node: Node) => (
        <EmbeddedEntry
          allowedEmbeds={allowedEmbeds}
          node={node}
          serverData={serverData}
        />
      ),

      [BLOCKS.EMBEDDED_ASSET]: (node: Node) => {
        try {
          if (!node.data.target.fields) return null
          const { url, description } = node.data.target.fields
          return (
            <Image
              alt={description}
              className="img-responsive"
              src={url}
              style={{ maxWidth: '100%' }}
            />
          )
        } catch (e) {
          console.log(e)
          return null
        }
      },
      [INLINES.HYPERLINK]: (node: Node, children: ReactNode) => (
        <Hyperlink className={hyperlinkClassName} node={node}>
          {children}
        </Hyperlink>
      ),
    },
  }
  return (
    <div className={css.RichTextBody}>
      {documentToReactComponents(richTextBody, richTextOptions)}
    </div>
  )
}

export default RichTextBody
