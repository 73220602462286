import classNames from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import {
  minimumFormattedPriceFromOffers,
  sectionAnchor,
} from 'hello_baby/lib/helpers'
import css from './WinningProductRecommendations.styles.scss'
import { WinningProductRecommendationsProps } from './WinningProductRecommendations.types'
import { Carousel, CarouselItem } from '../Utilities'
import { ProductLink } from '../ProductCardSection/ProductCard'

const WinningProductRecommendations = ({
  fields,
}: WinningProductRecommendationsProps) => {
  const hasProductCard = (product: ProductLink) => {
    if (!product) return false

    if (typeof window !== 'undefined')
      return !!document.querySelector(`#${sectionAnchor(product.fields.name)}`)

    return true
  }
  const productRecommendationsWithProductCards =
    fields.productRecommendations.filter((productRecommendation) =>
      hasProductCard(
        productRecommendation.fields.recommendedProduct as ProductLink
      )
    )

  return (
    <section
      className={css.WinningProductRecommendations__container}
      id={sectionAnchor(fields.title || 'Our Picks')}
    >
      <div className={css.WinningProductRecommendations__carouselContainer}>
        <Carousel
          horizontalMobileScroll
          className={css.WinningProductRecommendations__carouselContainerMargin}
          headerText={fields.title || 'Our Picks'}
        >
          {productRecommendationsWithProductCards.map(
            (productRecommendation) => (
              <CarouselItem>
                <div
                  className={classNames(
                    css.WinningProductRecommendations__productRecommendationContainer,
                    {
                      [css.WinningProductRecommendations__productRecommendationContainerWithHeader]:
                        !!productRecommendation.fields.recommendationLabel,
                      [css.WinningProductRecommendations__productRecommendationContainerBabylistPick]:
                        productRecommendation.fields.recommendationLabel ===
                        'Babylist Pick',
                    }
                  )}
                >
                  <div
                    className={classNames(
                      css.WinningProductRecommendations__productRecommendationInnerContainer,
                      {
                        [css.WinningProductRecommendations__productRecommendationInnerContainerWithHeader]:
                          !!productRecommendation.fields.recommendationLabel,
                        [css.WinningProductRecommendations__productRecommendationInnerContainerBabylistPick]:
                          productRecommendation.fields.recommendationLabel ===
                          'Babylist Pick',
                      }
                    )}
                  >
                    {!!productRecommendation.fields.recommendationLabel && (
                      <div
                        className={classNames(
                          css.WinningProductRecommendations__recommendationLabel,
                          {
                            [css.WinningProductRecommendations__recommendationLabelBabylistPick]:
                              productRecommendation.fields
                                .recommendationLabel === 'Babylist Pick',
                          }
                        )}
                      >
                        {productRecommendation.fields.recommendationLabel}
                      </div>
                    )}
                    {!!productRecommendation.fields.recommendedProduct && (
                      <>
                        <div
                          className={
                            css.WinningProductRecommendations__productImage
                          }
                        >
                          <img
                            alt={
                              productRecommendation.fields.recommendedProduct
                                .fields.name
                            }
                            src={
                              productRecommendation.fields.recommendedProduct
                                .fields.image.url
                            }
                          />
                        </div>
                        <div
                          className={
                            css.WinningProductRecommendations__productName
                          }
                        >
                          {
                            productRecommendation.fields.recommendedProduct
                              .fields.name
                          }
                        </div>
                        <div
                          className={
                            css.WinningProductRecommendations__productPrice
                          }
                        >
                          From{' '}
                          {minimumFormattedPriceFromOffers(
                            productRecommendation.fields.recommendedProduct
                              .fields.offers
                          )}
                        </div>
                        <div>
                          <Button
                            block
                            className={
                              css.WinningProductRecommendations__learnMoreButton
                            }
                            href={`#${sectionAnchor(
                              productRecommendation.fields.recommendedProduct
                                .fields.name
                            )}`}
                            size="sm"
                            variant="secondary"
                          >
                            Learn More
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </CarouselItem>
            )
          )}
        </Carousel>
      </div>
    </section>
  )
}

export default WinningProductRecommendations
