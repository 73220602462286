export const minimumFormattedPriceFromOffers = (offers) => {
  const pricesArray = offers.map((offer) => parseFloat(offer.price))
  const minPrice = Math.min(...pricesArray)

  return minPrice.toLocaleString('us-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
  })
}

export const sectionAnchor = (text) =>
  text
    ?.trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/g, '')
    .replace(/\s/g, '-')
