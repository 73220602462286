import { Dispatch, SetStateAction, useState } from 'react'
import StickyHeader from 'components/sticky-header'
import classNames from 'classnames'
import { sectionAnchor } from 'hello_baby/lib/helpers'
// @ts-ignore
import Tappable from 'react-tappable'
import css from './TableOfContents.styles.scss'

export interface CollapsibleTableOfContentsProps {
  headings: Heading[]
  selectedHeading: string | null
  setSelectedHeading: Dispatch<SetStateAction<string | null>>
}

export const CollapsibleTableOfContents = ({
  headings,
  selectedHeading,
  setSelectedHeading,
}: CollapsibleTableOfContentsProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [headersDisplayed, setHeadersDisplayed] = useState(
    headings.length >= 6 ? headings.slice(0, 6) : headings
  )

  const handleCollapse = () => {
    if (isOpen) {
      setHeadersDisplayed(headings.slice(0, 6))
    } else {
      setHeadersDisplayed(headings)
    }
    setIsOpen(!isOpen)
  }
  return (
    <>
      <StickyHeader
        className={css.TableOfContents__stickyHeader}
        fromArticle={true}
        headings={headings}
        selectedHeading={selectedHeading}
        setSelectedHeading={setSelectedHeading}
        title="In this Article"
      />
      <span className={css.TableOfContents__titleLine}>
        <strong>In this Article</strong>
      </span>
      <hr className={classNames('mbl', css.TableOfContents__titleLine)} />
      <div className={css.TableOfContents__mobileTocCollapsibleContainer}>
        {headersDisplayed.map((heading) => (
          <a href={`#${sectionAnchor(heading.text)}`} key={heading.text}>
            <ul
              className={classNames(css.TableOfContents__mobileHeadingText, {
                [css.TableOfContents__heading3]: heading.headingType === '3',
              })}
            >
              {heading.text}
            </ul>
          </a>
        ))}
        <Tappable onClick={handleCollapse}>
          <div className={css.TableOfContents__chevronBorder}>
            <span className="h3">
              <span
                className={classNames(
                  'fa fa-angle-right',
                  css.TableOfContents__chevron,
                  {
                    [css.TableOfContents__activeChevron]: isOpen,
                  }
                )}
              />
            </span>
          </div>
        </Tappable>
      </div>
    </>
  )
}
