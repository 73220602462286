import { Component } from 'react'
import PropTypes from 'prop-types'
// @ts-ignore
import { AutoAffix } from 'react-overlays'
import classNames from 'classnames'
import withCurrentUser from 'shared/HOC/withCurrentUser'
import { ProgressBar } from 'components/progress-bar'
import { getScrollTracker } from 'shared/scroll-tracker'
import { TableOfContents } from 'hello_baby/components/TableOfContents/TableOfContents'
import {
  lockBodyScroll,
  unlockBodyScroll,
} from 'shared/utils/lockBodyScroll/lockBodyScroll'
import css from './sticky-header.scss'

class StickyHeader extends Component {
  static propTypes = {
    hideTitle: PropTypes.bool,
    title: PropTypes.string.isRequired,
    leftActionComponent: PropTypes.object,
    rightActionComponent: PropTypes.object,
    triggerY: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
      .isRequired,
    currentUser: PropTypes.object,
    className: PropTypes.string,
    headings: PropTypes.array,
    fromArticle: PropTypes.bool,
    selectedHeading: PropTypes.string,
    setSelectedHeading: PropTypes.func,
  }

  static defaultProps = { triggerY: 75, hideTitle: false, fromArticle: false }

  scrollTracker = null

  state = {
    percentScrolled: 0,
    showHeadings: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
    this.scrollTracker = getScrollTracker('navbar-default', 'navbar-secondary')
  }

  componentWillUnmount() {
    this.scrollTracker.resetScrollArea()
    window.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll = () => {
    this.setState({
      percentScrolled: this.scrollTracker.getPercentScrolled(
        this.childContentContainer
      ),
    })
  }

  handleShowHeadings = () => {
    this.setState((prevState) => {
      const showHeadings = !prevState.showHeadings
      if (showHeadings) {
        this.openHeadings(prevState)
      } else {
        this.closeHeadings(prevState)
      }
    })
  }

  openHeadings = (prevState) => {
    lockBodyScroll('body')
    this.setState({ showHeadings: !prevState.showHeadings })
  }

  closeHeadings = (prevState) => {
    unlockBodyScroll('body')
    this.setState({ showHeadings: !prevState.showHeadings })
  }

  scrollToTop() {
    window.scrollTo(0, 0)
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      children,
      className,
      currentUser,
      title,
      leftActionComponent,
      rightActionComponent,
      hideTitle,
      headings,
      fromArticle,
      selectedHeading,
      setSelectedHeading,
    } = this.props
    const { percentScrolled, showHeadings } = this.state
    const triggerY = (this.props.triggerY || 400) * -1

    const utilityBarEnabled =
      typeof window !== 'undefined' &&
      !window.location.href.includes('embedded') &&
      !currentUser

    return (
      <>
        <AutoAffix
          affixClassName={classNames(
            css.affixed,
            className,
            utilityBarEnabled && css.utilityBarEnabled
          )}
          viewportOffsetTop={triggerY}
        >
          <div className={css.stickyHeader}>
            <div
              className={classNames(css.container, hideTitle && css.hideTitle)}
            >
              {!hideTitle && (
                <div className={css.header}>
                  <div className={css.controlWrapper}>
                    {leftActionComponent}
                  </div>

                  <div
                    className={css.titleContainer}
                    onClick={
                      fromArticle
                        ? this.handleShowHeadings
                        : this.scrollToTop.bind(this)
                    }
                  >
                    <div
                      className={classNames('h4 bold', css.title, {
                        [css.articleTitleAlignment]: fromArticle,
                      })}
                    >
                      {title}
                    </div>
                  </div>
                  {fromArticle && (
                    <div
                      className={classNames(css.chevron, {
                        [css.activeShowingHeadings]: showHeadings,
                      })}
                    >
                      <span className="h3">
                        <span
                          className="fa fa-angle-right"
                          onClick={this.handleShowHeadings}
                        />
                      </span>
                    </div>
                  )}
                  <div className={css.controlWrapper}>
                    {rightActionComponent}
                  </div>
                </div>
              )}
              <div>
                {showHeadings && (
                  <>
                    <hr />
                    <div className={css.Toc__container}>
                      <TableOfContents
                        handleCloseStickyHeader={this.handleShowHeadings}
                        headings={headings}
                        selectedHeading={selectedHeading}
                        setSelectedHeading={setSelectedHeading}
                        showInThisArticle={false}
                      />
                    </div>
                  </>
                )}
              </div>
              <ProgressBar
                color={css.progressBarColor}
                height={css.progressBarHeight}
                percent={percentScrolled}
              />
            </div>
          </div>
        </AutoAffix>
        <div ref={(el) => (this.childContentContainer = el)}>{children}</div>
        {showHeadings && <div className={css.overlay} />}
      </>
    )
  }
}

export default withCurrentUser(StickyHeader)
