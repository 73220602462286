// eslint-disable-next-line import/no-extraneous-dependencies
import { Button } from 'baby-design'
import classNames from 'classnames'

import { useEffect, useState } from 'react'
import { sectionAnchor } from 'hello_baby/lib/helpers'
import _ from 'lodash'
import css from './TableOfContents.styles.scss'

export interface TableOfContentsProps {
  headings: Heading[]
  handleCloseStickyHeader?: () => void
  showInThisArticle: boolean
  selectedHeading: string | null
  setSelectedHeading: (heading: string) => void
}

export const TableOfContents = ({
  headings,
  handleCloseStickyHeader,
  showInThisArticle,
  selectedHeading,
  setSelectedHeading,
}: TableOfContentsProps) => {
  const [headingElement, setHeadingElement] = useState<
    Record<string, HTMLElement | null>
  >({})

  const clickHeaderHandler = () => {
    handleCloseStickyHeader?.()
  }

  useEffect(() => {
    const result: Record<string, HTMLElement | null> = {}

    headings.forEach((heading) => {
      const anchorTag = sectionAnchor(heading.text)
      result[anchorTag] = document.getElementById(anchorTag)
    })
    setHeadingElement(result)
  }, [headings])

  useEffect(() => {
    const handleScroll = () => {
      const headingInView = headings.find((heading) => {
        const element = headingElement[sectionAnchor(heading.text)]
        if (element) {
          const { top, bottom } = element.getBoundingClientRect()
          return top <= 500 && bottom > 61
        }
        return false
      })

      if (headingInView) {
        setSelectedHeading(sectionAnchor(headingInView.text))
      }
    }

    handleScroll()
    const debouncedScroll = _.debounce(handleScroll, 100)
    window.addEventListener('scroll', debouncedScroll)
    return () => {
      window.removeEventListener('scroll', debouncedScroll)
    }
  }, [selectedHeading, setSelectedHeading, headings, headingElement])

  return (
    <>
      {showInThisArticle && (
        <p className={css.TableOfContents_inThisArticleText}>In This Article</p>
      )}
      {headings.map((heading) => (
        <Button
          block
          className={classNames(css.TableOfContents__headingButton, {
            [css.active_heading]:
              selectedHeading === sectionAnchor(heading.text),
            [css.notActiveHeading]:
              selectedHeading !== sectionAnchor(heading.text),
          })}
          href={`#${sectionAnchor(heading.text)}`}
          size="md"
          variant="inverted"
          onClick={() => {
            clickHeaderHandler()
          }}
        >
          <div
            className={classNames({
              [css.TableOfContents__buttonContainer]:
                selectedHeading === sectionAnchor(heading.text),
            })}
          >
            <div
              className={classNames({
                [css.TableOfContents__activeIndicator]:
                  selectedHeading === sectionAnchor(heading.text),
              })}
            />
            <span
              className={classNames(css.TableOfContents__headingText, {
                [css.TableOfContents__heading3]: heading.headingType === '3',
                [css.activeHeadingText]:
                  selectedHeading === sectionAnchor(heading.text),
              })}
            >
              {heading.text}
            </span>
          </div>
        </Button>
      ))}
    </>
  )
}
